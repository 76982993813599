<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.registro_informativo.filtro.codigo')"
      :placeholder="$t('modulos.registro_informativo.filtro.codigo')"
      em-filtro
    />
    <input-date
      v-model="filtro.dataEmissao"
      class="col-12 col-md-6"
      :label="$t('modulos.registro_informativo.filtro.data_emissao')"
      :placeholder="$t('modulos.registro_informativo.filtro.data_emissao')"
      em-filtro
    />
    <input-date
      v-model="filtro.dataOcorrencia"
      class="col-12 col-md-6"
      :label="$t('modulos.registro_informativo.filtro.data_ocorrencia')"
      :placeholder="$t('modulos.registro_informativo.filtro.data_ocorrencia')"
      em-filtro
    />
    <input-text
      v-model="filtro.ordemServico"
      class="col-12 col-md-6"
      :label="$t('modulos.registro_informativo.filtro.ordem_servico')"
      :placeholder="$t('modulos.registro_informativo.filtro.ordem_servico')"
      em-filtro
    />
    <input-text
      v-model="filtro.nome"
      class="col-12 col-md-6"
      :label="$t('modulos.registro_informativo.filtro.nome')"
      :placeholder="$t('modulos.registro_informativo.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.participante"
      class="col-12 col-md-6"
      :label="$t('modulos.registro_informativo.filtro.participante')"
      :placeholder="$t('modulos.registro_informativo.filtro.participante')"
      em-filtro
    />
    <input-text
      v-model="filtro.usuarioResponsavel"
      class="col-12"
      :label="$t('modulos.registro_informativo.filtro.usuario_responsavel')"
      :placeholder="$t('modulos.registro_informativo.filtro.usuario_responsavel')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
  data() {
    return {};
  },
};
</script>
